import React, { useState, useEffect } from "react";
import logo from "./../static/logo.png";
import { useLangContext, SET_LANG } from "./../contexts/LangContext";
import { Link, useLocation } from "react-router-dom";
import { getPage } from "./../text";

export default function Header() {
  const location = useLocation();
  const [curr, setCurr] = useState("");
  const [isShow, setIsShow] = useState(false);
  const langs = [
    {
      name: "ENG",
      slug: "ENG",
    },
    {
      name: "РУС",
      slug: "RUS",
    },
    {
      name: "ҚАЗ",
      slug: "KAZ",
    },
  ];

  const { langDispatch, lang } = useLangContext();
  const changeLang = (lg) => {
    langDispatch({
      type: SET_LANG,
      payload: lg,
    });
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setCurr(location.pathname);
    if (isShow) {
      setIsShow(!isShow);
    }
  }, [location]);
  const showMenu = () => {
    setIsShow(!isShow);
  };
  return (
    <div className="Header">
      <div className="Header_top">
        <ul className="langs">
          {langs.map((item, idx) => (
            <li onClick={() => changeLang(item.slug)} key={idx}>
              <div
                className={`langs_item ${item.slug === lang ? "active" : ""}`}
              >
                {item.name}
              </div>
            </li>
          ))}
          <button className="trigger" onClick={() => showMenu()}>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </ul>
        <div className={`nav ${isShow ? "show" : ""}`}>
          <Link className={`nav_item ${curr === "/" ? "active" : ""}`} to={"/"}>
            {getPage(lang, "main")}
          </Link>
          <div className="nav_item">
            {getPage(lang, "about")}
            <div className="nav_sublist">
              <Link
                className={`nav_item ${curr === "/about" ? "active" : ""}`}
                to={"/about"}
              >
                {getPage(lang, "about")}
              </Link>
              {/* <Link className={`nav_item ${curr === '/management' ? 'active':''}`} to={'/management'}>{getPage(lang, 'management')}</Link> */}
              <Link
                className={`nav_item ${curr === "/documents" ? "active" : ""}`}
                to={"/documents"}
              >
                {getPage(lang, "documents")}
              </Link>
              {/* <a target="_blank" href="/document.pdf" className="nav_item">Документы</a> */}
            </div>
          </div>
          <Link
            className={`nav_item ${curr === "/news-events" ? "active" : ""}`}
            to={"/news-events"}
          >
            {getPage(lang, "newsEvents")}
          </Link>
          <Link
            className={`nav_item ${curr === "/notifications" ? "active" : ""}`}
            to={"/notifications"}
          >
            {getPage(lang, "notifications")}
          </Link>
          {/* <Link className={`nav_item ${curr === '/projects' ? 'active':''}`} to={'/projects'}>{getPage(lang, 'main')}</Link> */}
          <div
            className="nav_item"
            onClick={() =>
              window.scrollTo({
                top: document.body.clientHeight,
                behavior: "smooth",
              })
            }
          >
            {getPage(lang, "contacts")}
          </div>
        </div>
      </div>
      <div className="Header_main">
        <h1 className="main-title">
          <img src={logo} alt="" />
          NU and NIS Foundation
        </h1>
      </div>
    </div>
  );
}
// ENG ҚАЗ РУС

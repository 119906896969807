import Accordion from "./../components/Accordion";
import Gallery from "./../components/Gallery";

import { useLangContext } from "./../contexts/LangContext";

import img8 from "./../static/NIS 8.JPG";
import img9 from "./../static/NIS 9.JPG";
import img10 from "./../static/NIS 10.JPG";
import img11 from "./../static/NIS 11.JPG";
import img12 from "./../static/NIS 12.JPG";
import img13 from "./../static/NIS 13.JPG";
import img14 from "./../static/NIS 14.jpg";
import img15 from "./../static/NIS 15.JPG";
import img16 from "./../static/NIS 16.jpg";
import img17 from "./../static/NIS 17.jpg";
import img18 from "./../static/NIS 18.JPG";
import img19 from "./../static/NIS 19.JPG";
import img20 from "./../static/NIS 20.JPG";
import img21 from "./../static/NIS 21.jpg";

import univer from "./../static/univer.jpg";
import poster from "./../static/poster3.jpeg";
import nis from "./../static/nis.jpg";

import { main, mission, vision } from "./../text";

export default function Home() {
  const { lang } = useLangContext();
  return (
    <div className="page">
      <div className="mainpics">
        <div className="mainpics__wrapper">
          <a
            target="_blank"
            href="https://nu.edu.kz/"
            className="mainpics__img"
          >
            <p className="mainpics__img_title">Nazarbayev University</p>
            <img src={poster} alt="NU" />
          </a>
          <a
            target="_blank"
            href="https://nis.edu.kz/"
            className="mainpics__img"
          >
            <p className="mainpics__img_title">
              Nazarbayev Intellectual Schools
            </p>
            <img src={nis} alt="NIS" />
          </a>
        </div>
      </div>

      <div className="main">
        {main[lang].map((item, idx) => (
          <p
            className="main-content"
            dangerouslySetInnerHTML={{ __html: item }}
            key={idx}
          ></p>
        ))}
      </div>

      <Gallery
        images={[
          img8,
          img9,
          img10,
          img11,
          img12,
          img13,
          img14,
          img15,
          img16,
          img17,
          img18,
          img19,
          img20,
          img21,
        ]}
        slidesToShow={2}
        slidesToScroll={1}
      />

      <div className="label-wrapper">
        {[mission[lang], vision[lang]].map((item, idx) => (
          <div className="Label" key={idx}>
            <h3 className="Label__heading">{item.heading}</h3>
            <p
              className="Label__content"
              dangerouslySetInnerHTML={{ __html: item.content }}
            ></p>
          </div>
        ))}
      </div>
    </div>
  );
}

import axios from "axios";
import { API_URL } from "./env";

export const getNotifications = async (lang) => {
  try {
    const notifications = await axios.get(
      `${API_URL}/notifications?lang=${lang}`
    );
    return notifications;
  } catch (error) {
    console.log("Error", error);
  }
};

export const getSingleNotification = async (id) => {
  try {
    const notification = await axios.get(`${API_URL}/notifications/${id}`);
    return notification;
  } catch (error) {
    console.log("Error", error);
  }
};

export const getPosts = async (lang) => {
  try {
    const posts = await axios.get(`${API_URL}/posts?lang=${lang}`);
    return posts;
  } catch (error) {
    console.log("Error", error);
  }
};

export const getSinglePost = async (id) => {
  try {
    const post = await axios.get(`${API_URL}/posts/${id}`);
    return post;
  } catch (error) {
    console.log("Error", error);
  }
};

export const getManagements = async () => {
  try {
    const managements = await axios.get(`${API_URL}/managements`);
    return managements;
  } catch (error) {
    console.log("Error", error);
  }
};
export const getDocs = async () => {
  try {
    const docs = await axios.get(`${API_URL}/docs`);
    return docs;
  } catch (error) {
    console.log("Error", error);
  }
};

import { getPage } from '../text';
import Accordion from './../components/Accordion';
import Article from './../components/Article';
import { useLangContext } from './../contexts/LangContext';
import { aboutPage, structureOfFund, strategy } from './../text';

export default function About() {
    const { lang } = useLangContext();
    return (
        <div className="page">
            <h1 className="page__heading">{getPage(lang, 'about')}</h1>
            <div className="page__content">
                {/* {JSON.stringify(content.aboutPage[lang])} */}
                <Article data={aboutPage[lang]} />
                <div className="article">
                    <p className="article__heading">{structureOfFund[lang].heading}</p>
                    <Accordion data={structureOfFund[lang].content} />
                </div>
                <div className="Blocks">
                    <h3 className="Blocks__heading">{strategy[lang].heading}</h3>
                    <div className="Blocks__wrapper">
                        <div className="Blocks__item">
                            <p dangerouslySetInnerHTML={{__html: strategy[lang].content[0]}} ></p>
                        </div>
                        <div className="Blocks__item">
                            <p dangerouslySetInnerHTML={{__html: strategy[lang].content[1]}} ></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
  }
  
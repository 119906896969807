import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getNotifications } from "./../services";
import { getPage } from "./../text";
import { useLangContext } from "./../contexts/LangContext";
import { useAppContext, SET_LOADING } from "./../contexts/AppContext";

export default function Notifications() {
  const { lang } = useLangContext();
  const { appDispatch } = useAppContext();
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    appDispatch({
      type: SET_LOADING,
      payload: true,
    });
    getNotifications(lang)
      .then((res) => {
        setNotifications(res.data.reverse());
      })
      .catch((err) => {
        appDispatch({ type: SET_LOADING, payload: false });
      })
      .finally((res) => {
        appDispatch({ type: SET_LOADING, payload: false });
      });
  }, [lang]);

  useEffect(() => {
    console.log("### Notifications", window.location);
  }, [notifications]);
  return (
    <div className="page">
      <h1 className="page__heading">{getPage(lang, "notifications")}</h1>
      <div className="Post">
        {notifications.map((notification, idx) => (
          <Link
            to={`/notifications/${notification["_id"]}`}
            className="Post__wrapper"
            key={idx}
          >
            <div className="Post__item">
              <div
                className="Post__poster"
                style={{
                  backgroundImage: `url('${
                    notification.uploadedPic
                      ? `${
                          window.location.hostname === "localhost"
                            ? "http://localhost:8000"
                            : window.location.origin
                        }/admin/public/pictures/${
                          notification.uploadedPic.path
                        }`
                      : ""
                  }')`,
                }}
              >
                <p className="Post__title">{notification.title}</p>
              </div>
              <p className="Post__preview">{notification.preContent}</p>
              {notification.created && (
                <p className="Post__created">
                  {notification.created.split("T")[0]}
                </p>
              )}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

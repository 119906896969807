import React from 'react';

export default function Accordion({ data }) {
    const [selected, setSelected] = React.useState()
    return (
        <div className="Accordion">
            {data.map((item, idx) => (
                <div className="Accordion__item" key={idx}>
                    <p onClick={() => {
                        setSelected(idx === selected ? null:idx)
                    }} className="Accordion__title">{item.title}</p>
                    {selected === idx && <p className="Accordion__content" dangerouslySetInnerHTML={{__html: item.content}}></p>}
                </div>
            ))}
        </div>
    )
}

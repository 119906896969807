import { getPage } from "../text"
import { useLangContext } from './../contexts/LangContext';

export default function Projects() {
  const { lang } = useLangContext();
  return (
    <div className="page">
    <h1 className="page__heading">{getPage(lang, 'projects')}</h1>
    </div>
  )
}

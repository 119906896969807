import { useEffect, useState } from "react";
import Slider from "react-slick";

export default function Gallery({images, slidesToShow = 1, slidesToScroll = 1}) {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: (window.innerWidth > 800) ? slidesToShow : 1,
        slidesToScroll: (window.innerWidth > 800) ? slidesToShow : 1,
        arrows: true,
    };
    return (
        <div className="Gallery">
            <Slider {...settings}>
                {images.map((img, idx) => (
                    <div className="Gallery__item" key={idx}>
                        <div className="img" style={{backgroundImage: `url('${img}')`}}></div>
                    </div>
                ))}
            </Slider>
        </div>
    );
}

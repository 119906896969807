import React, { createContext, useReducer, useContext } from "react";

export const AppContext = createContext();

const initialState = {
    loading: false
}

export const SET_LOADING = "SET_LOADING";

export const appReducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
         ...state,
         loading: action.payload
      }
    default:
      return initialState;
  }
};

export const AppProvider = (props) => {
  const [app, appDispatch] = useReducer(appReducer, initialState);

  return (
    <AppContext.Provider value={{ appDispatch, loading: app.loading }}>
      {props.children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};

import React from 'react';

export default function Article({ data }) {
    return (
        <>
            <div className="Article">
                {data.heading && <p className="Article__heading" dangerouslySetInnerHTML={{__html: data.heading}} ></p>}
                {data.content && <p className="Article__content" dangerouslySetInnerHTML={{__html: data.content}} ></p>}
            </div>
        </>
    )
}

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getPosts } from "./../services";
import { getPage } from "./../text";
import { useLangContext } from "./../contexts/LangContext";
import { useAppContext, SET_LOADING } from "./../contexts/AppContext";

export default function NewsEvents() {
  const { lang } = useLangContext();
  const { appDispatch } = useAppContext();
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    appDispatch({
      type: SET_LOADING,
      payload: true,
    });
    getPosts(lang)
      .then((res) => {
        setPosts(res.data.reverse());
      })
      .catch((err) => {
        appDispatch({ type: SET_LOADING, payload: false });
      })
      .finally((res) => {
        appDispatch({ type: SET_LOADING, payload: false });
      });
  }, [lang]);
  return (
    <div className="page">
      <h1 className="page__heading">{getPage(lang, "newsEvents")}</h1>
      <div className="Post">
        {posts.map((post, idx) => (
          <Link
            to={`/news-events/${post["_id"]}`}
            className="Post__wrapper"
            key={idx}
          >
            <div className="Post__item">
              <div
                className="Post__poster"
                style={{
                  backgroundImage: `url('${
                    post.uploadedPic
                      ? `${
                          window.location.hostname === "localhost"
                            ? "http://localhost:8000"
                            : window.location.origin
                        }/admin/public/pictures/${post.uploadedPic.path}`
                      : ""
                  }')`,
                }}
              >
                <p className="Post__title">{post.title}</p>
              </div>
              <p className="Post__preview">{post.preContent}</p>
              {post.created && (
                <p className="Post__created">{post.created.split("T")[0]}</p>
              )}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

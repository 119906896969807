import { useEffect, useState } from 'react';
import { getDocs } from '../services';
import { getPage } from '../text';
import { useLangContext } from './../contexts/LangContext';
import doc from './../static/doc.png';
import { UPLOADS_URL } from './../env'


export default function Documents() {
    const [docs, setDocs] = useState([])
    useEffect(() => {
        getDocs()
            .then(res => {
                setDocs(res.data)
            })
            .catch(err => { })
            .finally(res => { })
    }, [])
    // const [files, setFiles] = useState([
    //     {
    //         ENG: {
    //             title: 'NF asset and management memo',
    //             url: '/memo-eng.pdf',
    //         },
    //         RUS: {
    //             title: 'Памятка об активах и управлении NF',
    //             url: '/memo-rus.pdf',
    //         },
    //         KAZ: {
    //             title: 'Памятка об активах и управлении NF',
    //             url: '/memo-rus.pdf',
    //         },
    //     },
    //     {
    //         ENG: {
    //             title: 'ОТЧЕТ ПО ФИНАНСОВЫМ ПОКАЗАТЕЛЯМ на 2021',
    //             url: '/report-2021.pdf',
    //         },
    //         RUS: {
    //             title: 'ОТЧЕТ ПО ФИНАНСОВЫМ ПОКАЗАТЕЛЯМ на 2021',
    //             url: '/report-2021.pdf',
    //         },
    //         KAZ: {
    //             title: 'ОТЧЕТ ПО ФИНАНСОВЫМ ПОКАЗАТЕЛЯМ на 2021',
    //             url: '/report-2021.pdf',
    //         },
    //     },
    //     {
    //         ENG: {
    //             title: 'ЗАКОН РЕСПУБЛИКИ КАЗАХСТАН О статусе «Назарбаев Университет», «Назарбаев Интеллектуальные школы» и «Назарбаев Фонд»(с изменениями и дополнениями по состоянию на 27.12.2019 г.)',
    //             url: '/zakon.pdf'
    //         },
    //         RUS: {
    //             title: 'ЗАКОН РЕСПУБЛИКИ КАЗАХСТАН О статусе «Назарбаев Университет», «Назарбаев Интеллектуальные школы» и «Назарбаев Фонд»(с изменениями и дополнениями по состоянию на 27.12.2019 г.)',
    //             url: '/zakon.pdf'
    //         },
    //         KAZ: {
    //             title: 'ЗАКОН РЕСПУБЛИКИ КАЗАХСТАН О статусе «Назарбаев Университет», «Назарбаев Интеллектуальные школы» и «Назарбаев Фонд»(с изменениями и дополнениями по состоянию на 27.12.2019 г.)',
    //             url: '/zakon.pdf'
    //         },
    //     }
    // ])
    const { lang } = useLangContext();
    return (
        <div className="page">
            <h1 className="page__heading">{getPage(lang, 'documents')}</h1>
            <div className="Doc">
                {docs.map((item , idx) => (
                    <a target="_blank" href={`${UPLOADS_URL}${item.uploadedFile.path}`} key={item['_id']} className="Doc__item">
                        <div className="img" style={{backgroundImage: `url('${doc}')`}}></div>
                        <p className="Doc__title">{item.description}</p>
                    </a>
                ))}
            </div>
        </div>
    )
}

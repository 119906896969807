export const main = {
  ENG: [
    "Nazarbayev Fund was established in 2010 and is the endowment fund of Nazarbayev University and Nazarbayev Intellectual schools. The Fund is a non-profit organization and is based on the principles of autonomy, self-governance, collegial character when making decisions, social responsibility and transparency.",
    "The Fund is a classical endowment fund of educational organizations similar to the best international practice (endowment funds of Harvard, Stanford, Duke, etc.) and was established to provide funding for activities of the Nazarbayev University, Nazarbayev Intellectual schools and their organizations only.",
    "The Fund is governed by the <a target='_blank' class='content-link' href='/zakon.pdf'>Law of the Republic of Kazakhstan</a> “On the Status of Nazarbayev University, Nazarbayev Intellectual schools and Nazarbayev Fund”.",
    "According to the generally accepted international practice of endowment funds, the Fund’s income from investment activities is used to finance research activities (research centers and research projects of professors and students), purchase of required laboratory equipment and consumables, student support, etc. This approach makes it possible to provide funding for scientific and educational activities over the long run.",
  ],
  RUS: [
    "Частный Фонд Nazarbayev Fund  был открыт в 2010 году и является эндаумент фондом <a target='_blank' class='content-link' href='https://nu.edu.kz/'>Назарбаев Университета</a> и <a target='_blank' class='content-link' href='https://nis.edu.kz/'>Назарбаев Интеллектуальных Школ</a>. Фонд является некоммерческой организацией и основывается на принципах автономности, самоуправляемости, коллегиальности принятия решений, социальной ответственности и прозрачности.",
    "Фонд является классическим эндаумент-фондом образовательных организаций по аналогии с лучшей международной практикой (эндаумент-фонды Гарварда, Стэнфорда, Дьюка и пр.) и создан исключительно для обеспечения финансирования деятельности автономных организаций образования «<a target='_blank' class='content-link' href='https://nu.edu.kz/'>Назарбаев Университет</a>», «<a target='_blank' class='content-link' href='https://nis.edu.kz/'>Назарбаев Интеллектуальные школы» и их организаций.",
    "Деятельность фонда регулируется <a target='_blank' class='content-link' href='/zakon.pdf'>Законом Республики Казахстан</a> «О статусе «<a target='_blank' class='content-link' href='https://nu.edu.kz/'>Назарбаев Университета</a>», «<a target='_blank' class='content-link' href='https://nis.edu.kz/'>Назарбаев Интеллектуальные Школы</a>» и «Назарбаев Фонда»»",
    "Фонд согласно общепринятой международной практикой деятельности эндаумент фондов направляет доход от инвестиционной деятельности на финансирование научно-исследовательской деятельности (научные центры и научные проекты профессоров и студентов), приобретение необходимого лабораторного оборудования и расходных материалов, поддержки студентов и пр. Данный подход позволяет обеспечивать финансирование научно-образовательной деятельности в долгосрочной перспективе.",
  ],
  KAZ: [
    "Nazarbayev Fund жеке меншік Қоры 2010 жылы ашылды және <a target='_blank' class='content-link' href='https://nu.edu.kz/'>Назарбаев Университеті</a> мен Назарбаев Зияткерлік Мектептерінің эндаумент қоры болып табылады. Қор коммерциялық емес ұйым болып табылады және дербестік, өзін өзі басқару, шешімдерді қабылдауда алқалылық, әлеуметтік жауапкершілік және ашықтық қағидаларына негізделеді.",
    "Қор үздік әлемдік тәжірибеге ұқсас (Гарвард, Стэнфорд, Дьюк және т.б. эндаумент-қорлары) білім беру ұйымдарының классикалық эндаумент-қоры болып табылады және «<a target='_blank' class='content-link' href='https://nu.edu.kz/'>Назарбаев Университеті</a>», «Назарбаев Зияткерлік мектептері» дербес білім беру ұйымдарының қызметін және оларды ұйымдастыруды қаржыландыруды қамтамасыз ету мақсатында ғана құрылған.",
    "Қордың қызметі «<a target='_blank' class='content-link' href='https://nu.edu.kz/'>Назарбаев Университетінің</a>», «Назарбаев Зияткерлік Мектептерінің» және «Назарбаев Қорының» мәртебесі туралы» <a target='_blank' class='content-link' href='/zakon.pdf'>Қазақстан Республикасының Заңымен</a> реттеледі.",
    "Эндаумент қорлардың қызметінің жалпы қабылданған халықаралық тәжірибесіне сәйкес Қор инвестициялық қызметтен түскен табысты ғылыми-зерттеу қызметін (ғылыми орталықтар және профессорлар мен студенттердің ғылыми жобалары), қажетті зертханалық жабдықтар мен шығыс материалдарын сатып алуды, студенттерге қолдау көрсетуді және т.б. қаржыландыруға бағыттайды. Бұл тәсіл ұзақ мерзімді перспективада ғылыми-білім беру қызметін қаржыландыруды қамтамасыз етуге мүмкіндік береді.",
  ],
};

export const mission = {
  RUS: {
    heading: "Миссия",
    content:
      "Миссией Фонда является - оказание в долгосрочной перспективе поддержки <a target='_blank' class='content-link' href='https://nu.edu.kz/'>Назарбаев Университету</a> и <a target='_blank' class='content-link' href='https://nis.edu.kz/'>Назарбаев Интеллектуальным</a> школам путем предоставления стабильного финансирования их деятельности. Сферой направления средств Фонда являются исследовательские программы, стипендии и гранты, а также другие уставные цели.",
  },
  KAZ: {
    heading: "Миссия",
    content:
      "Қордың миссиясы – ұзақ мерзімді перспективада қызметтеріне тұрақты қаржыландыруды ұсыну жолымен <a target='_blank' class='content-link' href='https://nu.edu.kz/'>Назарбаев Университетіне</a> және Назарбаев Зияткерлік мектептеріне қолдау көрсету болып табылады. Қор қаражатын бағыттау саласы зерттеу бағдарламалары, стипендиялар мен гранттар, сондай-ақ өзге де жарғылық мақсаттар болып табылады.",
  },
  ENG: {
    heading: "Mission",
    content:
      "The mission of the Fund is to support Nazarbayev University and Nazarbayev Intellectual schools over the long run by providing stable financing for their activities. The Fund allocates its funds to research programs, scholarships and grants, and for other statutory purposes.",
  },
};

export const vision = {
  RUS: {
    heading: "Видение",
    content:
      "Достижение устойчивого развития, обеспечение необходимого уровня финансирования Университета и Школ независимо от внешних финансовых вливаний, а также формирование институционально-методологической основы для развития эндаумент фондов в Казахстане.",
  },
  KAZ: {
    heading: "Қызметтің көрінісі",
    content:
      "Университеттің және Мектептердің тұрақты дамуына қол жеткізу, сырттан қаржы құюларға қарамастан қажетті қаржыландыру деңгейін қамтамасыз ету, сондай-ақ Қазақстанда эндаумент қорлардың дамуына арналған институционалдық-әдістемелік негіздер қалыптастыру.",
  },
  ENG: {
    heading: "Vision",
    content:
      "Achieving the sustainable development, securing the necessary level of funding for the University and the Schools, regardless of external financial injections, and forming an institutional and methodological ground for development of endowment funds in Kazakhstan.",
  },
};

export const strategy = {
  RUS: {
    heading: "Стратегические цели",
    content: [
      "1. Финансовая цель - поддержание реальной стоимости активов фонда в долгосрочной перспективе после осуществления распределения средств. По достижении данной цели, дополнительной целью является увеличение реальной стоимости активов Фонда.",
      "2. Инвестиционная цель - предоставление финансирования из Фонда в размере, определенном в соответствии с политикой расходования средств Фонда.",
    ],
  },
  KAZ: {
    heading: "Стратегиялық мақсаттар",
    content: [
      "1. Қаржылық мақсат - қаражатты бөлуді жүзеге асырғаннан кейін ұзақ мерзімді перспективада қор активтерінің нақты құнын қолдап ұстау. Осы мақсатқа қол жеткізгеннен кейін Қор активтерінің нақты құнын ұлғайту қосымша мақсат болып табылады.",
      "2. Инвестициялық мақсат - Қор қаражатын жұмсау саясатына сәйкес анықталған мөлшерде Қордан қаржыландыру ұсыну.",
    ],
  },
  ENG: {
    heading: "Strategic Objectives",
    content: [
      "1. Financial objective is to maintain the real value of the Fund’s assets over the long run after distributions are made. Once this objective is achieved, an additional objective is to increase the real value of the Fund’s assets.",
      "2. Investment objective is to provide financing from the Fund in the amount determined in accordance with the Fund’s Spending Policy.",
    ],
  },
};

export const aboutPage = {
  RUS: {
    content:
      "Фонд является некоммерческой организацией, созданный исключительно для обеспечения финансирования деятельности <a target='_blank' class='content-link' href='https://nu.edu.kz/'><a target='_blank' class='content-link' href='https://nu.edu.kz/'>Назарбаев Университета</a> и <a target='_blank' class='content-link' href='https://nis.edu.kz/'>Назарабева Интеллектуальных школ</a>, а также их организаций.",
  },
  ENG: {
    content:
      "The Foundation is a non-profit organization created solely to provide funding for the activities of <a target='_blank' class='content-link' href='https://nu.edu.kz/'>Nazarbayev University</a> and <a target='_blank' class='content-link' href='https://nis.edu.kz/'>Nazarbayev Intellectual Schools</a>, as well as their organizations.",
  },
  KAZ: {
    content:
      "Фонд является некоммерческой организацией, созданный исключительно для обеспечения финансирования деятельности <a target='_blank' class='content-link' href='https://nu.edu.kz/'><a target='_blank' class='content-link' href='https://nu.edu.kz/'>Назарбаев Университета</a> и <a target='_blank' class='content-link' href='https://nis.edu.kz/'>Назарабева Интеллектуальных школ</a>, а также их организаций.",
  },
};

export const structureOfFund = {
  RUS: {
    heading: "Структура управления Фонда",
    content: [
      {
        title: "1) Высший попечительский совет - высший орган управления",
        content: `
        <p>В соответствии с распоряжением Президента Республики Казахстан от 26 сентября 2023 года № 78 «О назначении председателя Высшего попечительского совета «Назарбаев Университет», «Назарбаев Интеллектуальные школы» и «Назарбаев Фонд» и распоряжением Премьер-Министра РК от 27 сентября 2023 года №153-р «О некоторых вопросах Высшего попечительского совета «Назарбаев Университет», «Назарбаев Интеллектуальные школы» и «Назарбаев Фонд»» изменен состав Высшего попечительского совета вышеперечисленных организаций. Назначены следующие члены Высшего попечительского совета с 28 сентября 2023 года.</p>
        <br />
        <h2>Состав Высшего попечительского совета</h2>

        <br />

        <h3>Бектенов Олжас Абаевич</h3>
        <p>Премьер Министр Республики Казахстан, председатель Высшего Попечительского совета</p>
        <br />
        <h3>Байбазаров Нурлан Серикович</h3>
        <p>Заместитель Премьер-Министра – Министр национальной экономики Республики Казахстан</p>
        <br />
        <h3>Дуйсенова Тамара Босымбековна</h3>
        <p>Заместитель Премьер-Министра</p>
        <br />
        <h3>Иргалиев Асет Арманович</h3>
        <p>Постоянный представитель Республики Казахстан при Всемирной торговой организации и международных экономических организациях</p>
        <br />
        <h3>Илесанми Адесида</h3>
        <p>Исполняющий обязанности Президента «Назарбаев Университет»</p>
        <br />
        <h3>Саясат Нурбек</h3>
        <p>Министр науки и высшего образования Республики Казахстан</p>

        `,
      },
      {
        title: "2) Попечительский совет - орган управления",
        content: `<p>Попечительский совет является органом управления Фонда, осуществляющим общее руководство деятельностью Фонда. Структура, компетенция, порядок формирования и сроки полномочий членов Попечительского совета определяются Уставом Фонда и Положением о Попечительском Совете. В целом, основные обязанности Попечительского совета могут быть разделены на инвестиционное планирование, финансовое планирование, организационный надзор, аудит и отчетность. Таким образом, Попечительский совет также несет ответственность за утверждение ежегодного отчета и всех соответствующих документов, относящихся к каждой из указанных областей </p>

          <p>Решением Высшего попечительского совета Частного Фонда «Назарбаев Фонд» (далее – Фонд) от 02 октября 2023 г. №11 назначены члены Попечительского совета Фонда в следующем составе: </p>
          
          <br />
          <h3>Байбазаров Нурлан Серикович</h3>
          <p>заместитель Премьер министра Республики Казахстан, Министр национальной экономики Республики Казахстан </p>
          <br />
          <h3>Такиев Мади Токешович</h3>
          <p>Министр финансов Республики Казахстан</p>
          <br />
          <h3>Иргалиев Асет Арманович</h3>
          <p>Постоянный представитель Республики Казахстан при Всемирной торговой организации и международных экономических организациях</p>
          <br />
          <h3>Илесанми Адесида</h3>
          <p>Исполняющий обязанности Президента «Назарбаев Университет»</p>
          <br />
          <h3>Молдабекова Алия Мейрбековна</h3>
          <p>Заместитель Председателя Национального Банка Республики Казахстан </p>
          `,
      },
      // {
      //     "title": "3) Исполнительный комитет – комитет Попечительского совета",
      //     "content": "Исполнительный комитет является комитетом Попечительского совета, осуществляющий общее руководство Фондом в рамках полномочий, определяемых Попечительским советом. Согласно передовой мировой практике, именно Инвестиционный комитет (в случае Фонда – Исполнительный комитет) руководит процессом инвестирования. Исполнительный комитет формируется из числа отдельных членов Попечительского совета, представителей членов Попечительского совета, представителей Университета и Школ, отдельных членов Правления Фонда, инвестиционных профессионалов и иных лиц. К основным функциональным обязанностям Исполнительного комитета относятся: рассмотрение и представление инвестиционной политики на утверждение Попечительского совета; принятие решений о заключении/расторжении договора с управляющими компаниями и консультантами; обзор деятельности Правления. Структура, компетенция, порядок формирования и сроки полномочий членов Исполнительного комитета определяются Положением об Исполнительном комитете"
      // },
      {
        title: "3) Правление - исполнительный орган (коллегиальный)",
        content: `<p>Правление является коллегиальным исполнительным органом Фонда, которое осуществляет руководство текущей деятельностью Фонда и вправе принимать решения, утверждать внутренние документы Фонда по любым вопросам деятельности Фонда, не отнесенным к компетенции других органов и должностных лиц Фонда. Увеличение активов Фонда не оказывает влияние на функции Высшего попечительского совета и Попечительского совета Фонда. Тем не менее, передача полномочий по принятию решений от Исполнительного комитета Правлению будет осуществляться по мере приобретения Исполнительным органом достаточного опыта и знаний.</p>

          <p>&nbsp;</p>
          
          <p><span style="font-size:18px"><strong>Председатель Правления - Саринжипов Аслан Бакенов</strong></span></p>
          
          <p>&nbsp;</p>
          
          <p><em><strong>Образование и повышение квалификации</strong></em></p>
          
          <p><em>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</em></p>
          
          <ul>
            <li>2013 г. &ndash; Университет Пенсильвании (США, Лига Плюща), степень доктора в области администрирования высшего образования.</li>
            <li>2007 г. &ndash; Гарвардский Университет, <a href="https://ru.wikipedia.org/wiki/%25D0%2593%25D0%25B0%25D1%2580%25D0%25B2%25D0%25B0%25D1%2580%25D0%25B4%25D1%2581%25D0%25BA%25D0%25B8%25D0%25B9_%25D0%25B8%25D0%25BD%25D1%2581%25D1%2582%25D0%25B8%25D1%2582%25D1%2583%25D1%2582_%25D0%25B3%25D0%25BE%25D1%2581%25D1%2583%25D0%25B4%25D0%25B0%25D1%2580%25D1%2581%25D1%2582%25D0%25B2%25D0%25B5%25D0%25BD%25D0%25BD%25D0%25BE%25D0%25B3%25D0%25BE_%25D1%2583%25D0%25BF%25D1%2580%25D0%25B0%25D0%25B2%25D0%25BB%25D0%25B5%25D0%25BD%25D0%25B8%25D1%258F_%25D0%25B8%25D0%25BC._%25D0%2594%25D0%25B6%25D0%25BE%25D0%25BD%25D0%25B0_%25D0%25A4._%25D0%259A%25D0%25B5%25D0%25BD%25D0%25BD%25D0%25B5%25D0%25B4%25D0%25B8">Институт государственной политики имени Кеннеди</a>&nbsp;(США), стажировка, Программа развития финансовых институтов.</li>
            <li>2006 г. &ndash; <a href="https://ru.wikipedia.org/wiki/%25D0%259A%25D0%25B0%25D0%25B7%25D0%25B0%25D1%2585%25D1%2581%25D0%25BA%25D0%25B8%25D0%25B9_%25D1%258D%25D0%25BA%25D0%25BE%25D0%25BD%25D0%25BE%25D0%25BC%25D0%25B8%25D1%2587%25D0%25B5%25D1%2581%25D0%25BA%25D0%25B8%25D0%25B9_%25D1%2583%25D0%25BD%25D0%25B8%25D0%25B2%25D0%25B5%25D1%2580%25D1%2581%25D0%25B8%25D1%2582%25D0%25B5%25D1%2582_%25D0%25B8%25D0%25BC%25D0%25B5%25D0%25BD%25D0%25B8_%25D0%25A2._%25D0%25A0%25D1%258B%25D1%2581%25D0%25BA%25D1%2583%25D0%25BB%25D0%25BE%25D0%25B2%25D0%25B0">Казахский государственный экономический университет</a>, степень кандидата экономических наук, тема: Внешнеэкономическая деятельность.</li>
            <li><a href="https://ru.wikipedia.org/wiki/1997">1997</a> г. &ndash; Венская дипломатическая академия (<a href="https://ru.wikipedia.org/wiki/%25D0%2590%25D0%25B2%25D1%2581%25D1%2582%25D1%2580%25D0%25B8%25D1%258F">Австрия</a>), Программа обучения для сотрудников МИД РК.</li>
            <li>1993 &ndash; 1994 гг. &ndash; Университет Оклахомы (<a href="https://ru.wikipedia.org/wiki/%25D0%25A1%25D0%25A8%25D0%2590">США</a>), Специализация: мировая экономика (Программа Эдмунда Маски Конгресса США).</li>
            <li>1991 &ndash; 1996 гг. &ndash; <a href="https://ru.wikipedia.org/wiki/%25D0%259A%25D0%25B0%25D0%25B7%25D0%25B0%25D1%2585%25D1%2581%25D0%25BA%25D0%25B8%25D0%25B9_%25D1%258D%25D0%25BA%25D0%25BE%25D0%25BD%25D0%25BE%25D0%25BC%25D0%25B8%25D1%2587%25D0%25B5%25D1%2581%25D0%25BA%25D0%25B8%25D0%25B9_%25D1%2583%25D0%25BD%25D0%25B8%25D0%25B2%25D0%25B5%25D1%2580%25D1%2581%25D0%25B8%25D1%2582%25D0%25B5%25D1%2582_%25D0%25B8%25D0%25BC%25D0%25B5%25D0%25BD%25D0%25B8_%25D0%25A2._%25D0%25A0%25D1%258B%25D1%2581%25D0%25BA%25D1%2583%25D0%25BB%25D0%25BE%25D0%25B2%25D0%25B0">Казахский государственный экономический университет</a> им. Т. Рыскулова, экономист &ndash; международник, с отличием.</li>
          </ul>
          
          <p>&nbsp;</p>
          
          <p><strong><em>Карьера</em></strong></p>
          
          <p>&nbsp;</p>
          
          <ul>
            <li>2016 год &ndash; по настоящее время - Председатель Правления в &laquo;Назарбаев Фонде&raquo; (Образовательный эндаумент фонд НУ и НИШ).</li>
            <li>2013 &ndash; 2016 гг. &ndash; Министр образования и науки РК.</li>
            <li>2011 &ndash; 2013 гг. &ndash; Председатель управляющего совета НУ, член Попечительского Совета НИШ.&nbsp;</li>
            <li>2010 &ndash; 2011 гг. &ndash; Президент &laquo;Назарбаев Университета&raquo;.</li>
            <li>2009 &ndash; 2010 гг. &ndash; Руководитель проектной компании по созданию Назарбаев Университета.</li>
            <li><a href="https://ru.wikipedia.org/wiki/2007">2007</a> &ndash; <a href="https://ru.wikipedia.org/wiki/2009">2009</a>&nbsp;гг. &ndash; Заместитель Председателя Правления АО &laquo;Национальный аналитический центр при Правительстве и Национальном банке Республики Казахстан&raquo;.&nbsp;</li>
          </ul>
          
          <p>&nbsp;</p>
          
          <p><strong><em>Курируемые направления</em>: </strong>макроэкономика, монетарная политика, финансовые рынки, человеческий капитал.&nbsp;</p>
          
          <p>&nbsp;</p>
          
          <ul>
            <li><a href="https://ru.wikipedia.org/wiki/2002">2002</a> &ndash; <a href="https://ru.wikipedia.org/wiki/2007">2007</a>&nbsp;гг.&nbsp;&ndash; координатор проектов Всемирного банка регионального офиса по Центральной Азии, департамент <a href="https://ru.wikipedia.org/wiki/%25D0%2598%25D0%25BD%25D1%2584%25D1%2580%25D0%25B0%25D1%2581%25D1%2582%25D1%2580%25D1%2583%25D0%25BA%25D1%2582%25D1%2583%25D1%2580%25D0%25B0">инфраструктурных</a> проектов, департамент развития&nbsp;<a href="https://ru.wikipedia.org/wiki/%25D0%25A4%25D0%25B8%25D0%25BD%25D0%25B0%25D0%25BD%25D1%2581%25D0%25BE%25D0%25B2%25D1%258B%25D0%25B5_%25D1%2580%25D1%258B%25D0%25BD%25D0%25BA%25D0%25B8">финансовых рынков</a>.&nbsp;</li>
            <li>1998 &ndash; 2002 гг. &ndash; атташе по экономическим вопросам в Посольстве&nbsp;РК в США (г. Вашингтон).</li>
            <li>1997 г. &ndash; Министерство иностранных дел РК, департамент Америки, атташе по внешнеэкономическим вопросам и инвестициям.</li>
            <li>1996 г. &ndash; референт в Исполком Межгосударственного совета Центральной Азии.</li>
          </ul>
          
          <p><a href="https://ru.wikipedia.org/wiki/%25D0%259E%25D1%2580%25D0%25B4%25D0%25B5%25D0%25BD_%25D0%259A%25D1%2583%25D1%2580%25D0%25BC%25D0%25B5%25D1%2582">Орден Курмет</a>&nbsp;(<a href="https://ru.wikipedia.org/wiki/2012">2012 г.</a>)</p>
          
          <p>&nbsp;</p>
          
          <p><strong><em>Семейное положение</em>:</strong> Женат, пятеро детей.</p>
          `,
      },
    ],
  },
  KAZ: {
    heading: "Қордың басқару құрылымы.",
    content: [
      {
        title: "1) Жоғарғы қамқоршылық кеңес - жоғарғы басқару органы;",
        content:
          "1) Жоғарғы қамқоршылық кеңес Қордың жоғарғы басқару органы болып табылады. Жоғарғы қамқоршылық кеңестің құрылымы, құзыреті, құрылу тәртібі мен мүшелерінің өкілеттік мерзімдері, оның шешімдер қабылдау тәртібі «<a target='_blank' class='content-link' href='https://nu.edu.kz/'>Назарбаев Университетінің</a>», «Назарбаев Зияткерлік мектептерінің» және «Назарбаев Қорының» мәртебесі туралы» Заңмен, сондай-ақ Жоғарғы қамқоршылық кеңес туралы ережемен анықталады.",
      },
      {
        title: "2) Қамқоршылық кеңес - басқару органы;",
        content:
          "2) Жоғарғы қамқоршылық кеңес Қордың қызметіне жалпы басшылық ететін Қордың жоғарғы басқару органы болып табылады. Қамқоршылық кеңесінің құрылымы, мүшелерінің құзыреті, құрылу тәртібі мен өкілеттіктерінің мерзімдері Қордың Жарғысымен және Қамқоршылық кеңес туралы Ережемен анықталады. Жалпы, Қамқоршылық кеңестің негізгі міндеттерін инвестициялық жоспарлау, қаржылық жоспарлау, ұйымдастырушылық қадағалау, аудит және есептілік тармақтарына бөлуге болады. Осылайша Қамқоршылық кеңес сондай-ақ жыл сайынғы есептің және көрсетілген салалардың әрқайсысына қатысы бар барлық құжаттардың бекітілуіне жауапкершілік көтереді.",
      },
      {
        title: "3) Атқарушы комитет - Қамқоршылық кеңестің комитеті;",
        content:
          "3) Атқарушы комитет Қамқоршылық кеңес тарапынан анықталатын өкілеттіктер шеңберінде Қорға жалпы басшылық ететін Қамқоршылық кеңестің комитеті болып табылады. Әлемдегі озық тәжірибеге сәйкес дәл осы Инвестициялық комитет (Қор жағдайында – Атқарушы комитет) инвестициялау үдерісін басқарады. Атқарушы комитет Қамқоршылық кеңестің жеке мүшелерінің, Қамқоршылық кеңес өкілдерінің Университет және Мектептердің өкілдерінің, Қор Басқармасының жеке мүшелерінің, инвестициялық кәсіби мамандардың және өзге тұлғалардың қатарынан құрылады. Атқарушы комитеттің негізгі функционалдық міндеттеріне келесілер жатады: инвестициялық саясатты қарастыру және Қамқоршылық кеңестің бекітуіне ұсыну; басқарушы компаниялармен және кеңесшілермен шарттар жасасу/бұзу туралы шешімдер қабылдау; Басқарма қызметіне шолу. Атқарушы комитеттің құрылымы, мүшелерінің құзыреті, құрылу тәртібі және өкілеттіктерінің мерзімдері Атқарушы комитет туралы Ережемен анықталады.",
      },
      {
        title: "4) Басқарма - атқарушы орган (алқалы).",
        content: `<p>Правление является коллегиальным исполнительным органом Фонда, которое осуществляет руководство текущей деятельностью Фонда и вправе принимать решения, утверждать внутренние документы Фонда по любым вопросам деятельности Фонда, не отнесенным к компетенции других органов и должностных лиц Фонда. Увеличение активов Фонда не оказывает влияние на функции Высшего попечительского совета и Попечительского совета Фонда. Тем не менее, передача полномочий по принятию решений от Исполнительного комитета Правлению будет осуществляться по мере приобретения Исполнительным органом достаточного опыта и знаний.</p>

          <p>&nbsp;</p>
          
          <p><span style="font-size:18px"><strong>Председатель Правления - Саринжипов Аслан Бакенов</strong></span></p>
          
          <p>&nbsp;</p>
          
          <p><em><strong>Образование и повышение квалификации</strong></em></p>
          
          <p><em>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</em></p>
          
          <ul>
            <li>2013 г. &ndash; Университет Пенсильвании (США, Лига Плюща), степень доктора в области администрирования высшего образования.</li>
            <li>2007 г. &ndash; Гарвардский Университет, <a href="https://ru.wikipedia.org/wiki/%25D0%2593%25D0%25B0%25D1%2580%25D0%25B2%25D0%25B0%25D1%2580%25D0%25B4%25D1%2581%25D0%25BA%25D0%25B8%25D0%25B9_%25D0%25B8%25D0%25BD%25D1%2581%25D1%2582%25D0%25B8%25D1%2582%25D1%2583%25D1%2582_%25D0%25B3%25D0%25BE%25D1%2581%25D1%2583%25D0%25B4%25D0%25B0%25D1%2580%25D1%2581%25D1%2582%25D0%25B2%25D0%25B5%25D0%25BD%25D0%25BD%25D0%25BE%25D0%25B3%25D0%25BE_%25D1%2583%25D0%25BF%25D1%2580%25D0%25B0%25D0%25B2%25D0%25BB%25D0%25B5%25D0%25BD%25D0%25B8%25D1%258F_%25D0%25B8%25D0%25BC._%25D0%2594%25D0%25B6%25D0%25BE%25D0%25BD%25D0%25B0_%25D0%25A4._%25D0%259A%25D0%25B5%25D0%25BD%25D0%25BD%25D0%25B5%25D0%25B4%25D0%25B8">Институт государственной политики имени Кеннеди</a>&nbsp;(США), стажировка, Программа развития финансовых институтов.</li>
            <li>2006 г. &ndash; <a href="https://ru.wikipedia.org/wiki/%25D0%259A%25D0%25B0%25D0%25B7%25D0%25B0%25D1%2585%25D1%2581%25D0%25BA%25D0%25B8%25D0%25B9_%25D1%258D%25D0%25BA%25D0%25BE%25D0%25BD%25D0%25BE%25D0%25BC%25D0%25B8%25D1%2587%25D0%25B5%25D1%2581%25D0%25BA%25D0%25B8%25D0%25B9_%25D1%2583%25D0%25BD%25D0%25B8%25D0%25B2%25D0%25B5%25D1%2580%25D1%2581%25D0%25B8%25D1%2582%25D0%25B5%25D1%2582_%25D0%25B8%25D0%25BC%25D0%25B5%25D0%25BD%25D0%25B8_%25D0%25A2._%25D0%25A0%25D1%258B%25D1%2581%25D0%25BA%25D1%2583%25D0%25BB%25D0%25BE%25D0%25B2%25D0%25B0">Казахский государственный экономический университет</a>, степень кандидата экономических наук, тема: Внешнеэкономическая деятельность.</li>
            <li><a href="https://ru.wikipedia.org/wiki/1997">1997</a> г. &ndash; Венская дипломатическая академия (<a href="https://ru.wikipedia.org/wiki/%25D0%2590%25D0%25B2%25D1%2581%25D1%2582%25D1%2580%25D0%25B8%25D1%258F">Австрия</a>), Программа обучения для сотрудников МИД РК.</li>
            <li>1993 &ndash; 1994 гг. &ndash; Университет Оклахомы (<a href="https://ru.wikipedia.org/wiki/%25D0%25A1%25D0%25A8%25D0%2590">США</a>), Специализация: мировая экономика (Программа Эдмунда Маски Конгресса США).</li>
            <li>1991 &ndash; 1996 гг. &ndash; <a href="https://ru.wikipedia.org/wiki/%25D0%259A%25D0%25B0%25D0%25B7%25D0%25B0%25D1%2585%25D1%2581%25D0%25BA%25D0%25B8%25D0%25B9_%25D1%258D%25D0%25BA%25D0%25BE%25D0%25BD%25D0%25BE%25D0%25BC%25D0%25B8%25D1%2587%25D0%25B5%25D1%2581%25D0%25BA%25D0%25B8%25D0%25B9_%25D1%2583%25D0%25BD%25D0%25B8%25D0%25B2%25D0%25B5%25D1%2580%25D1%2581%25D0%25B8%25D1%2582%25D0%25B5%25D1%2582_%25D0%25B8%25D0%25BC%25D0%25B5%25D0%25BD%25D0%25B8_%25D0%25A2._%25D0%25A0%25D1%258B%25D1%2581%25D0%25BA%25D1%2583%25D0%25BB%25D0%25BE%25D0%25B2%25D0%25B0">Казахский государственный экономический университет</a> им. Т. Рыскулова, экономист &ndash; международник, с отличием.</li>
          </ul>
          
          <p>&nbsp;</p>
          
          <p><strong><em>Карьера</em></strong></p>
          
          <p>&nbsp;</p>
          
          <ul>
            <li>2016 год &ndash; по настоящее время - Председатель Правления в &laquo;Назарбаев Фонде&raquo; (Образовательный эндаумент фонд НУ и НИШ).</li>
            <li>2013 &ndash; 2016 гг. &ndash; Министр образования и науки РК.</li>
            <li>2011 &ndash; 2013 гг. &ndash; Председатель управляющего совета НУ, член Попечительского Совета НИШ.&nbsp;</li>
            <li>2010 &ndash; 2011 гг. &ndash; Президент &laquo;Назарбаев Университета&raquo;.</li>
            <li>2009 &ndash; 2010 гг. &ndash; Руководитель проектной компании по созданию Назарбаев Университета.</li>
            <li><a href="https://ru.wikipedia.org/wiki/2007">2007</a> &ndash; <a href="https://ru.wikipedia.org/wiki/2009">2009</a>&nbsp;гг. &ndash; Заместитель Председателя Правления АО &laquo;Национальный аналитический центр при Правительстве и Национальном банке Республики Казахстан&raquo;.&nbsp;</li>
          </ul>
          
          <p>&nbsp;</p>
          
          <p><strong><em>Курируемые направления</em>: </strong>макроэкономика, монетарная политика, финансовые рынки, человеческий капитал.&nbsp;</p>
          
          <p>&nbsp;</p>
          
          <ul>
            <li><a href="https://ru.wikipedia.org/wiki/2002">2002</a> &ndash; <a href="https://ru.wikipedia.org/wiki/2007">2007</a>&nbsp;гг.&nbsp;&ndash; координатор проектов Всемирного банка регионального офиса по Центральной Азии, департамент <a href="https://ru.wikipedia.org/wiki/%25D0%2598%25D0%25BD%25D1%2584%25D1%2580%25D0%25B0%25D1%2581%25D1%2582%25D1%2580%25D1%2583%25D0%25BA%25D1%2582%25D1%2583%25D1%2580%25D0%25B0">инфраструктурных</a> проектов, департамент развития&nbsp;<a href="https://ru.wikipedia.org/wiki/%25D0%25A4%25D0%25B8%25D0%25BD%25D0%25B0%25D0%25BD%25D1%2581%25D0%25BE%25D0%25B2%25D1%258B%25D0%25B5_%25D1%2580%25D1%258B%25D0%25BD%25D0%25BA%25D0%25B8">финансовых рынков</a>.&nbsp;</li>
            <li>1998 &ndash; 2002 гг. &ndash; атташе по экономическим вопросам в Посольстве&nbsp;РК в США (г. Вашингтон).</li>
            <li>1997 г. &ndash; Министерство иностранных дел РК, департамент Америки, атташе по внешнеэкономическим вопросам и инвестициям.</li>
            <li>1996 г. &ndash; референт в Исполком Межгосударственного совета Центральной Азии.</li>
          </ul>
          
          <p><a href="https://ru.wikipedia.org/wiki/%25D0%259E%25D1%2580%25D0%25B4%25D0%25B5%25D0%25BD_%25D0%259A%25D1%2583%25D1%2580%25D0%25BC%25D0%25B5%25D1%2582">Орден Курмет</a>&nbsp;(<a href="https://ru.wikipedia.org/wiki/2012">2012 г.</a>)</p>
          
          <p>&nbsp;</p>
          
          <p><strong><em>Семейное положение</em>:</strong> Женат, пятеро детей.</p>
          `,
      },
    ],
  },
  ENG: {
    heading: "Governance Structure of the Fund",
    content: [
      {
        title: "1) Supreme Board of Trustees - the supreme governing body;",
        content:
          "1) The Supreme Board of Trustees is the supreme governing body of the Fund. The structure, competence, formation procedure and terms of office of the members of the Supreme Board of Trustees and the procedure for making decisions are determined by the Law “On the Status of Nazarbayev University, Nazarbayev Intellectual schools and Nazarbayev Fund” and the Regulations of the Supreme Board of Trustees.",
      },
      {
        title: "2) Board of Trustees - the governing body;",
        content:
          "2) The Board of Trustees is the governing body of the Fund, which is responsible for the general management of the Fund. The structure, competence, formation procedure and term of office of the members of the Board of Trustees are determined by the Charter of the Fund and the Regulations on the Board of Trustees. In general, the main duties of the Board of Trustees may be divided into the investment planning, financial planning, organizational supervision, auditing and reporting. Thus, the Board of Trustees is also responsible for approving the annual report and all relevant documents relating to each of these areas.",
      },
      {
        title:
          "3) Executive Committee – the committee of the Board of Trustees;",
        content:
          "3) The Executive Committee is the committee of the Board of Trustees, which provides the general management of the Fund within the powers defined by the Board of Trustees. According to international best practice, it is the Investment Committee (in the case of the Fund, the Executive Committee) that guides the investment process. The Executive Committee is formed from the individual Trustees, representatives of the Trustees, representatives of the University and the Schools, individual members of the Management Board of the Fund, investment professionals and other persons. The main functional duties of the Executive Committee include: reviewing and presenting the investment policy to the Board of Trustees for approval; deciding on the entering into/termination of the contracts with management companies and advisors; and reviewing the activities of the Management Board. The structure, competence, formation procedure and term of office of the members of the Executive Committee are determined by the Regulations on the Executive Committee.",
      },
      {
        title: "4) Management Board – the executive body (collegial).",
        content: `<p>The Management Board is the collegial executive body of the Fund, which manages the current activities of the Fund and has the right to take decisions, approve the internal documents of the Fund on any issues of the Fund&rsquo;s activities not attributed to the competence of other bodies and officers of the Fund.</p>

        <p>&nbsp;</p>
        
        <p><strong><span style="font-size:18px"><span style="font-family:Arial,Helvetica,sans-serif">Chairman of the Board </span><strong>- Aslan Sarinzhipov</strong></span></strong></p>
        
        <p>&nbsp;</p>
        
        <p><span style="font-size:16px"><strong><em>Education and professional development</em></strong></span></p>
        
        <p><em>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</em></p>
        
        <ul>
          <li>2018 &ndash; University of Oxford, Said Business School, Oxford Blockchain Strategy Program.</li>
          <li>2011 &ndash; 2013 &ndash; University of Pennsylvania (USA), Graduate School of Education, EdD in Higher Education Administration.</li>
          <li>2007 &ndash; Harvard University, Kennedy School for Public Policy (USA), Executive Program, Financial Institutions for Private Enterprise Development.</li>
          <li>2006 &ndash; Kazakh State Economic University, Ph.D. in Economics, International economic relations.</li>
          <li>1997 &ndash; Vienna Diplomatic Academy (Austria), Training Program for employees of the Ministry of Foreign Affairs of the Republic of Kazakhstan.</li>
          <li>1993 &ndash; 1994 &ndash; University of Oklahoma (USA), International Economics (Edmund Muskie Followship Program).</li>
          <li>1991 &ndash; 1996 &ndash; T. Ryskulov Kazakh Economic University, economist - international economic relation specialist, with honors.</li>
        </ul>
        
        <p>&nbsp;</p>
        
        <p><strong><em><span style="font-size:16px">Career</span></em></strong></p>
        
        <p>&nbsp;</p>
        
        <ul>
          <li>2017 &ndash; present &ndash; Associate professor at Graduate School of Education teaching various courses on Educational Reform, Governance, and Management in Education.</li>
          <li>2016 &ndash; present &ndash; Chairman of the Board at the Nazarbayev Fund (Educational Endowment Fund for NU and NIS).</li>
          <li>2013 &ndash; 2016 &ndash; Minister of Education and Science of the Republic of Kazakhstan.</li>
          <li>2011 &ndash; 2013 &ndash; Chairman of the NU Management Council, member of the NIS Board of Trustees.</li>
          <li>2010 &ndash; 2011 &ndash; President of Nazarbayev University.</li>
          <li>2009 &ndash; 2010 &ndash; CEO of the project company for the creation of Nazarbayev University.</li>
          <li>2007 &ndash; 2009 &ndash; Deputy Chairman of the Board of JSC &quot;National Analytical Center under the Government and the National Bank of the Republic of Kazakhstan&quot;.</li>
        </ul>
        
        <p>&nbsp;</p>
        
        <p><em><strong><span style="font-size:16px">Supervised areas</span></strong></em>: macroeconomics, monetary policy, financial markets, human capital.</p>
        
        <p>&nbsp;</p>
        
        <p>&nbsp;</p>
        
        <ul>
          <li>2002 &ndash; 2007 &ndash; Project Coordinator of the World Bank Regional Office for Central Asia, Department of Infrastructure, Department of Financial and Private Markets Development.</li>
          <li>1998 &ndash; 2002 &ndash; attach&eacute; for economic affairs at the Embassy of the Republic of Kazakhstan in the United States (Washington).</li>
          <li>1997 &ndash; 1998 &ndash; Ministry of Foreign Affairs of the Republic of Kazakhstan, Department of America, Attache for Foreign Economic Affairs and Investments.</li>
          <li>1996 &ndash; 1997 &ndash; Assistant to the Chairman of Executive Committee of the Interstate Council of Central Asian states.</li>
        </ul>
        
        <p>Order of Kurmet (2012)</p>
        
        <p>&nbsp;</p>
        
        <p><span style="font-size:16px"><em><strong>Marital status:</strong></em></span> Married, five children.</p>
        `,
      },
    ],
  },
};

export const pages = {
  RUS: {
    main: "Главная",
    about: "О фонде",
    management: "Управление",
    documents: "Документы",
    newsEvents: "Новости и мероприятия",
    notifications: "Обьявления",
    projects: "Проекты",
    contacts: "Контакты",
  },
  ENG: {
    main: "Home",
    about: "About Fund",
    management: "Management",
    documents: "Documents",
    newsEvents: "News and Events",
    notifications: "Notifications",
    projects: "Projects",
    contacts: "Contacts",
  },
  KAZ: {
    main: "Басты бет",
    about: "Қор туралы",
    management: "Басқару",
    documents: "Құжаттама",
    newsEvents: "Жаңалықтар мен оқиғалар",
    notifications: "Жарнамалар",
    projects: "Жобалар",
    contacts: "Контактілер",
  },
};

export const footer = {
  RUS: {
    heading: "Контакты",
    address: "Адрес",
    email: "E-mail",
  },
  ENG: {
    heading: "Contacts",
    address: "Address",
    email: "E-mail",
  },
  KAZ: {
    heading: "Контактілер",
    address: "Адрес",
    email: "E-mail",
  },
};

export const getPage = (lang, page) => {
  return pages[lang][page];
};

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { getSinglePost } from "../services";
import { useLangContext } from "./../contexts/LangContext";
import { useAppContext, SET_LOADING } from "./../contexts/AppContext";
import { UPLOADS_URL } from "../env";
import doc from "./../static/doc.png";

const linkText = {
  ENG: "Link to post",
  RUS: "Ссылка на пост",
  KAZ: "Сілтеме",
};

export default function SiglePost() {
  const [post, setPost] = useState({});
  const { lang } = useLangContext();
  const { appDispatch } = useAppContext();
  const location = useLocation();
  const id = location.pathname.split("/news-events/")[1];
  useEffect(() => {
    appDispatch({
      type: SET_LOADING,
      payload: true,
    });
    getSinglePost(id)
      .then((res) => {
        console.log("### getSinglePost", res);
        setPost(res.data);
      })
      .catch((err) => {
        appDispatch({ type: SET_LOADING, payload: false });
      })
      .finally((res) => {
        appDispatch({ type: SET_LOADING, payload: false });
      });
  }, [lang]);
  return (
    <div className="page">
      <Link to={`/news-events`} className="back-btn">
        вернутся к постам
      </Link>
      {post.title && <h1 className="page__heading">{post.title[lang]}</h1>}

      {post.created && (
        <p style={{ padding: "5px 0", fontSize: "20px" }}>
          {post.created.split("T")[0]}
        </p>
      )}

      {post.postLink && (
        <a target="_blank" href={post.postLink}>
          {linkText[lang]}
        </a>
      )}

      {lang === "RUS" ? (
        <p
          style={{ padding: "30px 0", fontSize: "20px" }}
          dangerouslySetInnerHTML={{ __html: post.contentRus }}
        ></p>
      ) : null}

      {lang === "ENG" ? (
        <p
          style={{ padding: "30px 0", fontSize: "20px" }}
          dangerouslySetInnerHTML={{ __html: post.contentEng }}
        ></p>
      ) : null}

      {lang === "KAZ" ? (
        <p
          style={{ padding: "30px 0", fontSize: "20px" }}
          dangerouslySetInnerHTML={{ __html: post.contentKaz }}
        ></p>
      ) : null}

      {post.uploadedFile?.filename && (
        <div className="Doc">
          {post.uploadedFile?.filename.map((name, idx) => (
            <a
              key={idx}
              target="_blank"
              href={`${UPLOADS_URL}${post.uploadedFile.path[idx]}`}
              className="Doc__item"
            >
              <div
                className="img"
                style={{ backgroundImage: `url('${doc}')` }}
              ></div>
              <p className="Doc__title">{name}</p>
            </a>
          ))}
        </div>
      )}
    </div>
  );
}

import React, { createContext, useReducer, useContext } from "react";

export const LangContext = createContext();

const initialState = localStorage.getItem('lang') || "RUS";

export const SET_LANG = "SET_LANG";
// export const REMOVE = "REMOVE";
// export const REMOVE_ALL = "REMOVE_ALL";

export const langReducer = (state, action) => {
  switch (action.type) {
    case SET_LANG:
      localStorage.setItem('lang', action.payload)
      return action.payload
    default:
      return initialState;
  }
};

export const LangProvider = (props) => {
  const [lang, langDispatch] = useReducer(langReducer, initialState);

  return (
    <LangContext.Provider value={{ langDispatch, lang }}>
      {props.children}
    </LangContext.Provider>
  );
};

export const useLangContext = () => {
  return useContext(LangContext);
};

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, HashRouter } from "react-router-dom";

import './index.scss';
import App from './App';
import { LangProvider } from './contexts/LangContext';
import { AppProvider } from './contexts/AppContext';

ReactDOM.render(
  <HashRouter>
    <AppProvider>
      <LangProvider>
        <App />
      </LangProvider>
    </AppProvider>
  </HashRouter>,
  document.getElementById('root')
);
import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.scss";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Loader from "./components/Loader";

import Home from "./pages/Home";
import Projects from "./pages/Projects";
import NewsEvents from "./pages/NewsEvents";
import Notifications from "./pages/Notifications";
import Management from "./pages/Management";
import About from "./pages/About";
import Documents from "./pages/Documents";
import SiglePost from "./pages/SiglePost";
import SigleNotification from "./pages/SigleNotification";
import NotFound from "./pages/NotFound";

import { useAppContext } from "./contexts/AppContext";
import { useLangContext } from "./contexts/LangContext";

function App() {
  const { loading } = useAppContext();
  const { lang } = useLangContext();
  return (
    <div className="App">
      {loading && <Loader />}
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/management" element={<Management />} />
        <Route path="/news-events" element={<NewsEvents />} />
        <Route path="/news-events/:id" element={<SiglePost />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/notifications/:id" element={<SigleNotification />} />
        <Route path="/documents" element={<Documents />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer lang={lang} />
    </div>
  );
}

export default App;

import React from 'react';

export default function NotFound() {
  return (
      <div className="page full">
          <div className="page__content">
              <div className="NotFound">
                  <p className="NotFound__404">404</p>
                  <p className="NotFound__title">page not found</p>
              </div>
          </div>
      </div>
  )
}

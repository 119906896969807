import { useEffect, useState } from "react";
import { getManagements } from "../services";
import { getPage } from "../text"
import { useLangContext } from './../contexts/LangContext';
import { useAppContext, SET_LOADING } from './../contexts/AppContext';

export default function Management() {
    const { lang } = useLangContext();
    const { appDispatch } = useAppContext();
    const [managements, setManagements] = useState([])
    useEffect(() => {
        appDispatch({ type: SET_LOADING, payload: true})
        getManagements()
            .then(res => {
                // console.log('getManagements', res.data);
                setManagements(res.data)
            })
            .catch(err => {
                // console.log('getManagements:catch', err);
                appDispatch({ type: SET_LOADING, payload: false})
            })
            .finally(res => {
                // console.log('getManagements:finally', res);
                appDispatch({ type: SET_LOADING, payload: false})
            })
    }, [])
    return (
        <div className="page">
            <h1 className="page__heading">{getPage(lang, 'management')}</h1>
            <div className="page__content">
                <div className="Person">
                    {managements.map((item, idx) => (
                        <div className="Person__item" key={idx}>
                            <p className="Person__name">{item.name} - {item.position}</p>
                            <p className="Person__description">{item.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
